
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import { required } from "@/cms-services/consts";
@Component
export default class Home extends Vue {
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "review",
  });

  folderConfig: any = {
    className: "reviewFolder",
    rootFolder: "mainFolder",
  };

  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 3000,
    },
  });

  customCreateItemFields: any = [
    {
      editor: "enum",
      attrs: {
        label: "Пользователь",
        hidedetails: true,
        class: "py-0 my-0",
      },
      name: "userProfileId",
      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
      validations: [required()],
    },
    {
      editor: "enum",
      attrs: {
        label: "Класс",
        hidedetails: true,
        class: "py-0 my-0",
      },
      name: "targetClassId",
      config: {
        getItems: "getClasses",
        labelKeyName: "name",
        valueKeyName: "value",
        type: "select",
      },
      grid: {
        cols: 6,
      },
      validations: [required()],
    },
    {
      editor: "number",
      name: "targetObjectId",
      config: {},
      attrs: {
        type: "text",
        label: "Id подвязываеммого обьекта",
      },
      grid: {
        cols: 6,
      },
      validations: [required()],
    },
    {
      editor: "string",
      name: "caption",
      config: {},
      attrs: {
        type: "text",
        label: "Заголовок",
      },
      validations: [required()],
    },
    {
      editor: "memo",
      name: "description",
      config: {},
      attrs: {
        type: "text",
        label: "Отзыв",
      },
      validations: [required()],
    },
  ];
  loaded: boolean = false;

  async created() {
    await this.userProfileDataSource.get();
    this.loaded = true;
  }
  customCreateItemModel: any = {
    targetObjectId: null,
    targetClassId: null,
    userProfileId: null,
    caption: "",
    description: "",
  };

  actions: any = {
    getUserProfiles: () => this.userProfileDataSource.items,
    getClasses: () => [
      { name: "Component", value: 1 },
      { name: "Article", value: 2 },
      { name: "Page", value: 3 },
      { name: "News", value: 4 },
      { name: "WikiArticle", value: 5 },
      { name: "Disease", value: 6 },
      { name: "Symptom", value: 7 },
      { name: "Event", value: 8 },
      { name: "Product", value: 9 },
      { name: "Commodity", value: 10 },
      { name: "Service", value: 11 },
      { name: "Person", value: 13 },
      { name: "Doctor", value: 14 },
      { name: "ContentSource", value: 15 },
      { name: "ContentBlock", value: 16 },
      { name: "ObjectImage", value: 17 },
      { name: "EventSchedule", value: 18 },
      { name: "EventDay", value: 19 },
      { name: "EventTiming", value: 20 },
      { name: "EventLocation", value: 21 },
      { name: "EventSession", value: 22 },
      { name: "EventSessionItem", value: 23 },
      { name: "EventSessionModerator", value: 24 },
      { name: "EventSessionItemPerson", value: 25 },
      { name: "EventBroadcast", value: 26 },
      { name: "UserProfile", value: 27 },
      { name: "CompanyProfile", value: 28 },
      { name: "Region", value: 29 },
      { name: "Profile", value: 30 },
      { name: "Project", value: 31 },
      { name: "AudioFile", value: 32 },
      { name: "ObjectImageAlbum", value: 33 },
      { name: "ComponentImageAlbum", value: 33 },
      { name: "ImageContentBlock", value: 34 },
      { name: "ContentBlockImageAlbum", value: 35 },
      { name: "Author", value: 36 },
      { name: "MessangerMessage", value: 37 },
      { name: "MessangerProject", value: 38 },
      { name: "AudiofileProject", value: 39 },
      { name: "ContentBlockFile", value: 40 },
      { name: "ContentBlockImage", value: 41 },
      { name: "ContentForm", value: 42 },
      { name: "CrmProject", value: 43 },
      { name: "PhoneCall", value: 44 },
      { name: "ClientManager", value: 45 },
      { name: "PrivateClient", value: 46 },
      { name: "CorporateClient", value: 47 },
      { name: "Document", value: 48 },
      { name: "MailSubscriber", value: 49 },
      { name: "MailScenario", value: 50 },
      { name: "ClientEventFilter", value: 51 },
      { name: "ClientManagerFilter", value: 52 },
      { name: "DatabusMessage", value: 53 },
      { name: "DatabusRequestMessage", value: 54 },
      { name: "DatabusResponseMessage", value: 55 },
      { name: "DatabusErrorMessage", value: 56 },
      { name: "ClientContact", value: 57 },
      { name: "ClientBooking", value: 58 },
      { name: "DoctorBooking", value: 59 },
      { name: "GeoObject", value: 60 },
      { name: "Company", value: 61 },
      { name: "CompanyBranch", value: 62 },
      { name: "ComponentContact", value: 63 },
      { name: "ExternalLink", value: 64 },
      { name: "ClientEventSource", value: 65 },
      { name: "ClientEventSourceGroup", value: 66 },
      { name: "ClientPhoneCall", value: 67 },
      { name: "ClientEvent", value: 500 },
      { name: "Client", value: 501 },
      { name: "ClientEventAttachedFile", value: 502 },
      { name: "ComponentLinkedFile", value: 503 },
      { name: "ComponentLink", value: 504 },
      { name: "DocumentFolder", value: 505 },
      { name: "CommodityFolder", value: 506 },
      { name: "Cart", value: 507 },
      { name: "CartItem", value: 508 },
      { name: "CartFavorites", value: 509 },
      { name: "Order", value: 510 },
      { name: "OrderItem", value: 511 },
      { name: "CertificateTemplate", value: 512 },
      { name: "CertificateTemplateLayer", value: 513 },
      { name: "CertificateTemplateTextLayer", value: 517 },
      { name: "CertificateTemplateImageLayer", value: 518 },
      { name: "AttachedFile", value: 514 },
      { name: "EventCertificate", value: 515 },
      { name: "EventCertificatePerson", value: 516 },
      { name: "Consent", value: 519 },
      { name: "UserConsent", value: 520 },
      { name: "ClientWebOrder", value: 521 },
      { name: "ArticleFolder", value: 522 },
      { name: "PersonFolder", value: 523 },
      { name: "EventFolder", value: 524 },
      { name: "NewsFolder", value: 525 },
      { name: "ClientEventFolder", value: 526 },
      { name: "Folder", value: 527 },
      { name: "FolderAccess", value: 528 },
      { name: "ClientEventConsent", value: 529 },
      { name: "Video", value: 530 },
      { name: "VideoFolder", value: 531 },
      { name: "ProductModification", value: 532 },
      { name: "Brand", value: 533 },
      { name: "BrandFolder", value: 534 },
      { name: "ClientEventScenario", value: 535 },
      { name: "Webinar", value: 536 },
      { name: "OrderFolder", value: 537 },
      { name: "VideoTimeCode", value: 538 },
      { name: "OrderFile", value: 539 },
      { name: "EventCertificateLector", value: 540 },
      { name: "ClientFolder", value: 541 },
      { name: "ClientPhone", value: 542 },
      { name: "ClientEmail", value: 543 },
      { name: "EventRegistration", value: 544 },
      { name: "EventDayLocation", value: 545 },
      { name: "EventLocationSession", value: 546 },
      { name: "ProfessionalArea", value: 547 },
      { name: "ProfessionalAreaFolder", value: 548 },
      { name: "EventSessionProfessionalArea", value: 549 },
      { name: "EventSessionItemProfessionalArea", value: 550 },
      { name: "AttachedFileFolder", value: 551 },
      { name: "ResourceFolderAccess", value: 552 },
      { name: "ServiceFolder", value: 553 },
      { name: "Feature", value: 554 },
      { name: "GeoObjectFeature", value: 555 },
      { name: "PriceList", value: 556 },
      { name: "PriceListItem", value: 557 },
      { name: "Clinic", value: 558 },
      { name: "Station", value: 559 },
      { name: "FeaturesFolder", value: 560 },
      { name: "ClinicFolder", value: 561 },
      { name: "ComponentFeature", value: 555 },
      { name: "CompanyFolder", value: 62 },
      { name: "ClinicBranch", value: 563 },
      { name: "CompanyBranchPerson", value: 564 },
      { name: "ComponentReview", value: 565 },
      { name: "Review", value: 566 },
      { name: "Question", value: 567 },
      { name: "ReviewFolder", value: 568 },
      { name: "QuestionFolder", value: 56 },
    ],
  };

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Отзывы",
        disabled: true,
      },
    ];
  }
}
